

export default (() => {

  /**
   * calculate nav_overlay
   */
  function resizeNav() {
    //console.log(document.querySelector("#nav_fullscreen"))
    const node_fullscreen = document.querySelector("#nav_fullscreen");
    const node_overlay = document.querySelector("#nav_overlay");
    // Set the nav height to fill the window
    node_fullscreen.style.height = window.innerHeight + "px";

    // Set the circle radius to the length of the window diagonal,
    // this way we're only making the circle as big as it needs to be.
    var radius = Math.sqrt(Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2));
    var diameter = radius * 2;
    node_overlay.style.width = diameter + "px";
    node_overlay.style.height = diameter + "px";
    node_overlay.style.marginTop = -radius + "px";
    node_overlay.style.marginLeft = -radius + "px";
  }
  resizeNav();

  // Set up click and window resize callbacks, then init the nav.
  window.addEventListener("resize", () => { resizeNav() });


  // window.setTimeout(function () {
  //   document.querySelector(".header .nav_toggle").click();
  // }, 636)



  /** 
   * close:
   * catch clicks an follow link after nav_overlay is closed
   */
  document.querySelectorAll('#nav_fullscreen a:not(.nav_toggle):not([href^="#reservieren_"])').forEach(link => {
    link.addEventListener('click', function (e) {
      console.log("click on Link");
      const _ = this;//'save' the this context == myEl for later
      e.preventDefault();
      close();
      setTimeout(function () {
        window.open(_.href, _.target || '_self');
      }, 400)
    });
  });


  /**
   * open / close 
   */
  const body = document.querySelector("body");
  const offcanvas_show_class = "nav_show";
  let achorName_class = "nav_is_default";
  const overlay = document.querySelector("#nav_overlay");
  const open_links = document.querySelectorAll(".burger_menu, [href^='#reservieren_']");

  const close = (e) => {
    body.classList.remove(offcanvas_show_class);
    set_navOverlay_position(e);
    setTimeout(() => {
      body.classList.remove(achorName_class);
      achorName_class = "nav_is_default";
    }, 500);
    document.removeEventListener("keydown", escKey);
    document.documentElement.style.overflowY = "auto";
  }

  const open = (e) => {
    set_navOverlay_position(e);

    body.classList.add(offcanvas_show_class);
    body.classList.add(achorName_class);
    document.addEventListener("keydown", escKey);
    document.documentElement.style.overflowY = "hidden";
  }
  const escKey = (e) => {
    if (e.keyCode === 27) {
      close(false);
    }
  }

  const set_navOverlay_position = (e) => {
    if(!e)  return;
    const node_overlay = document.querySelector("#nav_overlay");
    node_overlay.style.top = e.clientY + "px";
    node_overlay.style.left = e.clientX + "px";
  }


  overlay.addEventListener("click", (e) => {
    e.preventDefault();
    e.stopPropagation();
    close(e);
  });

  open_links.forEach(element => {
    element.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (body.classList.contains(offcanvas_show_class)) {
        close(e);

      } else {
        if (e.target.hash) {
          achorName_class = "nav_is_" + e.target.hash.split('#')[1];
        }
        open(e);
      }

    });
  });

  // /* close offcanvas on any click inside offcanvas */
  // const linksInsideOffcanvas = document.querySelectorAll(".offcanvas a");
  // linksInsideOffcanvas.forEach(link => {
  //   link.addEventListener("click", close())
  // })

  /* toggle Reservierung */
  document.querySelectorAll('a.reservierung-toggle').forEach(link => {
    link.addEventListener('click', function (e) {
      console.log("click on Link",e.target.hash);
      const _ = this;//'save' the this context == myEl for later
      e.preventDefault();
      close(e);
      setTimeout(function () {
        if (e.target.hash) {
          achorName_class = "nav_is_" + e.target.hash.split('#')[1];
        }
        open(e);
      }, 600)
    });
  });




})();


