export default (() => {

  const body = document.querySelector("body");
  const intro_links = document.querySelectorAll(".link_left, .link_right");
  
  intro_links.forEach( link => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      console.log(link.className);
      body.classList.add("clicked_" + link.className);
      

      // ToDo => prerender / preload
      // https://www.macarthur.me/posts/best-ish-practices-for-dynamically-prefetching-and-prerendering-with-javascript
      let prerenderLink = document.createElement("link");
      prerenderLink.setAttribute("rel", "prefetch");
      prerenderLink.setAttribute("href", link.href);
      document.head.appendChild(prerenderLink);

      setTimeout(function () {
        window.open(link.href, "_self");
      }, 1150)

    });
  })


  /* remove class intro_animation */
  
  setTimeout(() => {
    body.classList.remove('intro_animation');
    body.classList.add('intro_animation_end');
  }, 3000);
  

})();