

// import "./lib/awesomplete.js";


// import "./module/touch_dropdown";
// import "./module/slideToggle.js";
// import "./module/offcanvas.js";
import "./module/nav_overlay_multi.js";
// import "./module/swiper.js";
// import "./module/warenkcorb_quantity.js";
// import "./module/autosize";
// import "./module/form_validation.js";
import "./module/scrollspy.js";
import "./module/home_intro.js";

import "./module/matomo";

[/Edg/, /Edge/].forEach( (name) => {
  if (name.test(navigator.userAgent)) {
    document.documentElement.className += " edge";
    // alert('Hello Microsoft User!');
    console.log(navigator.userAgent);
  }
})


// console.log("TEST");

/** 
 * MegaMenu add class .active to aktive Submenu Items in MenaMenu
 */
document.querySelectorAll(".mm_dropdown a").forEach(link => {
  if (link.pathname === window.location.pathname) {
    link.classList.add('active')
  }
});